/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$MES-theme-primary: mat.define-palette(mat.$light-blue-palette, 800, 600, 900);
$MES-theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$MES-theme-warn: mat.define-palette(mat.$red-palette);

$MES-theme: mat.define-light-theme($MES-theme-primary, $MES-theme-accent, $MES-theme-warn);

@include mat.all-legacy-component-themes($MES-theme);


body {  font-family: Roboto, "Helvetica Neue", sans-serif; }

@mixin md-icon-size($size: 36px) {
  font-size: $size;
  height: $size;
  width: $size;
  line-height: $size;
}

.md-drppicker .btn {
  background-color: #0277BD !important;
}

.md-drppicker td.active{
  background-color: #0277BD !important;
}

